import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import { StyledInner, StyledWrapper } from '../../components/common.style';
import Button from '../../components/buttons/Button';
import { tabletViews } from '../../utils/media';
import { H1Title, Subtitle1, Text2 } from '../../components/common.styles.font';

export const Wrapper = styled(StyledWrapper)`
    min-height: 90rem;
    display: flex;
    align-items: center;
    padding-top: 18rem;
    padding-bottom: 18rem;
    padding-right: var(--main-side-padding);
    padding-left: var(--main-side-padding);

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        padding-top: 14rem;
        padding-bottom: 14rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        display: block;
        padding-top: 12rem;
        min-height: 80rem;
    }
`;

const Inner = styled(StyledInner)`
    display: grid;
    grid-template-columns:
        minmax(28rem, 40rem)
        minmax(38rem, 60rem);

    grid-template-columns: minmax(24rem, 44rem) 1fr minmax(48rem, 62rem);
    grid-template-areas: 'text img';
    grid-template-areas: 'text . img';
    column-gap: 3rem;

    justify-content: space-between;
    align-items: center;

    /* max-width: 820px */
    @media ${tabletViews.xlg} {
        grid-template-columns: 1fr;
        grid-template-areas:
            'img'
            'text';
        row-gap: 4rem;
    }
`;

const TextWrapper = styled.div`
    grid-area: text;
`;

export const TitleWrapper = styled.div`
    width: 100%;
    position: relative;
    padding-top: 5rem;
    padding-bottom: 4rem;
    word-break: keep-all;

    .subtitle {
        color: var(--txt-inactive);
        margin-bottom: 1.4rem;
    }

    .desc {
        font-weight: 400;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding-top: 2rem;
    }
`;

const BtnsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.2rem;

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        flex-wrap: wrap;

        button {
            width: 100%;
        }
    }
`;

const ImgWrapper = styled.div`
    grid-area: img;
`;

const Img = styled.img`
    cursor: zoom-in;
    width: 100%;
    height: 100%;

    &.img-bg {
        display: block;
    }

    &.img-sm {
        display: none;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        max-width: 44rem;

        &.img-bg {
            display: none;
        }

        &.img-sm {
            display: block;
        }
    }
`;

interface HeroDetails {
    title: string;
    subtitle?: string;
    desc: string;
    img: { [key: string]: any[] };
    firstBtnTxt: string;
    firstBtnAction: () => void;
    secondBtnTxt: string | null;
    secondBtnAction: (arg0?: string) => void | null;
}

interface Props {
    dataName: string;
    curLang: string;
    heroDetails: HeroDetails;
    openLightbox: (arg0: any) => void;
}

const ProductHeroSection: React.FC<Props> = ({ dataName, curLang, heroDetails, openLightbox }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id="product__hero" className="bg-primary-gradient">
            <Inner>
                <ImgWrapper data-aos="fade-up" data-aos-delay="600">
                    <Img
                        className="img-bg"
                        src={heroDetails.img[curLang][0]}
                        alt={`${dataName}-img`}
                        onClick={() => openLightbox(heroDetails.img[curLang][0])}
                    />
                    <Img
                        className="img-sm"
                        src={heroDetails.img[curLang][1]}
                        alt={`${dataName}-img`}
                        onClick={() => openLightbox(heroDetails.img[curLang][1])}
                    />
                </ImgWrapper>
                <TextWrapper data-aos="fade-up" data-aos-delay="300">
                    <TitleWrapper>
                        <H1Title className={`product lang-${curLang}`}>{t(heroDetails.title)}</H1Title>
                        {heroDetails.subtitle && (
                            <Subtitle1 className={`lang-${curLang} subtitle`}>{t(heroDetails.subtitle)}</Subtitle1>
                        )}
                        <Text2 className={`lang-${curLang}`}>{t(heroDetails.desc)}</Text2>
                    </TitleWrapper>

                    <BtnsWrapper>
                        <Button
                            curLang={curLang}
                            fs="16"
                            width="fit-content"
                            appearance="primary"
                            txt={t(heroDetails.firstBtnTxt)}
                            handleClick={heroDetails.firstBtnAction}
                        />

                        {heroDetails.secondBtnTxt && (
                            <Button
                                curLang={curLang}
                                fs="16"
                                width="fit-content"
                                appearance="primary-outline"
                                txt={t(heroDetails.secondBtnTxt)}
                                icon={<UilArrowUpRight className="icon icon-arrow-up" />}
                                handleClick={() => heroDetails.secondBtnAction(curLang)}
                            />
                        )}
                    </BtnsWrapper>
                </TextWrapper>
            </Inner>
        </Wrapper>
    );
};

export default ProductHeroSection;
