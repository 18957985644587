import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MSLogo from '../../assets/logo/logo_Microsoft AI Cloud Partner.png';
import { desktopViews, tabletViews } from '../../utils/media';
import { H4Title } from '../../components/common.styles.font';
import { StyledWrapper, TitleWrapper } from '../../components/common.style';

const Wrapper = styled(StyledWrapper)`
    text-align: center;
`;

const Inner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10rem;

    img {
        width: 23rem;
    }

    /* max-width: 1040px */
    @media ${desktopViews.xxs} {
        gap: 8rem;
        img {
            width: calc(8vw + 15rem);
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        gap: 5rem;
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        gap: 4rem;

        img {
            width: calc(8vw + 13rem);
        }
    }

    /* max-width: 414px */
    @media (max-width: 414px) {
        gap: 3rem;

        img {
            width: 14rem;
        }
    }
`;

interface Props {
    curLang: string;
}

const PartnerSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id="main__partners" className="bg-tertiary">
            <TitleWrapper className="title-card-wrapper">
                <H4Title className={`lang-${curLang} title-span-tertiary`} data-aos-delay="80" data-aos="fade-up">
                    <span> {t('partners')}</span>
                </H4Title>
            </TitleWrapper>

            <Inner>
                <img src={MSLogo} alt="logo-microsoft" />
            </Inner>
        </Wrapper>
    );
};

export default PartnerSection;
