import styled from 'styled-components';

export const ButtonDefault = styled.button`
    cursor: pointer;
    position: relative;
    font-family: 'Roboto', 'Assistant', 'sans-serif';
    border: none;
    background: none;
    text-align: center;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
    user-select: none; /* supported by Chrome and Opera */
    border-radius: var(--btn-bd-r);
    margin: 0;
    padding: 0.8rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    letter-spacing: 0.5px;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 500;
    color: var(--btn-primary-txt);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', 'open-sans';
    }
`;

export const LinkBtn = styled.button`
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    color: var(--txt-inactive);
    font-size: 1.7rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    transition: none;

    background: var(--btn-secondary-bg);
    border: 1px solid var(--btn-secondary-bd);
    color: var(--btn-secondary-txt);

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', 'open-sans';
    }

    &:hover {
        background: var(--btn-secondary-bg-h);
        border: 1px solid var(--btn-secondary-bd-h);
        color: var(--btn-secondary-txt-h);
    }

    &:focus,
    &:active {
        background: var(--btn-secondary-bg-f);
        border: 1px solid var(--btn-secondary-bd-f);
        color: var(--btn-secondary-txt-f);
    }
`;
