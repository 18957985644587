import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledGridInner, StyledWrapper, TitleWrapper } from '../../components/common.style';
import Members from '../../components/Members';
import { tabletViews } from '../../utils/media';
import { H4Title, Text1 } from '../../components/common.styles.font';

const Wrapper = styled(StyledWrapper)``;

const Inner = styled(StyledGridInner)`
    width: 100%;
    grid-template-areas:
        'intro intro'
        'pName pDetails';

    .team-intro {
        margin-bottom: 12rem;
    }

    .team-profile-name {
        align-self: end;
        padding-left: 2rem;
        padding-bottom: 4rem;
    }

    .team-profile-details {
        margin-left: 3rem;
    }

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        .team-profile-details {
            margin-left: 2.4rem;
        }
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        .team-intro {
            margin-bottom: 5rem;
        }

        .team-profile-name {
            padding: 0;
            margin-bottom: 3rem;
        }
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        .team-intro {
            margin-bottom: 4rem;
        }
    }
`;

const Intro = styled.div`
    grid-area: intro;
    width: 70rem;

    /* max-width: 1536px */
    @media (max-width: 1536px) {
        width: 60rem;
    }

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        width: 100%;
    }
`;

interface Props {
    curLang: string;
}

const TeamSection: React.FC<Props> = ({ curLang }) => {
    const { t } = useTranslation();

    return (
        <Wrapper id="main__team" className="bg-secondary">
            <Inner className="team">
                <Intro className="team-intro">
                    <TitleWrapper className="title-intro-wrapper">
                        <H4Title
                            className={`lang-${curLang} title-span-secondary`}
                            data-aos="fade-up"
                            data-aos-delay="80"
                        >
                            <span>{t('team')}</span>
                        </H4Title>
                    </TitleWrapper>

                    <Text1 className={`lang-${curLang} team-text`}>{t('main.team.intro')}</Text1>
                </Intro>
                <Members curLang={curLang} />
            </Inner>
        </Wrapper>
    );
};

export default TeamSection;
