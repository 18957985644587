import React from 'react';
import styled from 'styled-components';
import { ButtonDefault } from './Buttons';
import { tabletViews } from '../../utils/media';

const Wrapper = styled(ButtonDefault)`
    width: 100%;

    &.fit-content {
        width: fit-content;
    }

    &.fs-15 {
        font-size: 1.5rem;
    }

    &.fs-16 {
        font-size: 1.6rem;
    }

    &.fs-17 {
        font-size: 1.7rem;
    }

    &.fs-18 {
        font-size: 1.8rem;
    }

    &.fs-20 {
        font-size: 2rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        &.fs-16,
        &.fs-17,
        &.fs-18 {
            font-size: 1.6rem;
        }
    }

    /* Primary */
    &.primary {
        background: var(--btn-primary-bg);
        border: 2px solid var(--btn-primary-bd);
        color: var(--btn-primary-txt);
    }

    &.primary:hover {
        background: var(--btn-primary-bg-h);
        border: 2px solid var(--btn-primary-bd-h);
        color: var(--btn-primary-txt-h);
    }

    &.primary:focus,
    &.primary:active {
        background: var(--btn-primary-bg-f);
        border: 2px solid var(--btn-primary-bd-f);
        color: var(--btn-primary-txt-f);
    }

    /* Primary Outline */
    &.primary-outline {
        background: var(--btn-primary-outline-bg);
        border: 2px solid var(--btn-primary-outline-bd);
        color: var(--btn-primary-outline-txt);
    }

    &.primary-outline:hover {
        background: var(--btn-primary-outline-bg-h);
        border: 2px solid var(--btn-primary-outline-bd-h);
        color: var(--btn-primary-outline-txt-h);
    }

    &.primary-outline:focus,
    &.primary-outline:active {
        background: var(--btn-primary-outline-bg-f);
        border: 2px solid var(--btn-primary-outline-bd-f);
        color: var(--btn-primary-outline-txt-f);
    }

    /* Secondary */
    &.secondary {
        background: var(--btn-secondary-bg);
        border: 2px solid var(--btn-secondary-bd);
        color: var(--btn-secondary-txt);
    }

    &.secondary:hover {
        background: var(--btn-secondary-bg-h);
        border: 2px solid var(--btn-secondary-bd-h);
        color: var(--btn-secondary-txt-h);
    }

    &.secondary:focus,
    &.secondary:active {
        background: var(--btn-secondary-bg-f);
        border: 2px solid var(--btn-secondary-bd-f);
        color: var(--btn-secondary-txt-f);
    }

    /* Secondary Outline */
    &.secondary-outline {
        background: var(--btn-secondary-outline-bg);
        border: 2px solid var(--btn-secondary-outline-bd);
        color: var(--btn-secondary-outline-txt);
    }

    &.secondary-outline:hover {
        background: var(--btn-secondary-outline-bg-h);
        border: 2px solid var(--btn-secondary-outline-bd-h);
        color: var(--btn-secondary-outline-txt-h);
    }

    &.secondary-outline:focus,
    &.secondary-outline:active {
        background: var(--btn-secondary-outline-bg-f);
        border: 2px solid var(--btn-secondary-outline-bd-f);
        color: var(--btn-secondary-outline-txt-f);
    }

    /* Light Outline */
    &.light-outline {
        background: var(--btn-light-outline-bg);
        border: 2px solid var(--btn-light-outline-bd);
        color: var(--btn-light-outline-txt);
    }

    &.light-outline:hover {
        background: var(--btn-light-outline-bg-h);
        border: 2px solid var(--btn-light-outline-bd-h);
        color: var(--btn-light-outline-txt-h);
    }

    &.light-outline:focus,
    &.light-outline:active {
        background: var(--btn-light-outline-bg-f);
        border: 2px solid var(--btn-light-outline-bd-f);
        color: var(--btn-light-outline-txt-f);
    }

    &.light-outline:disabled {
        background: var(--btn-light-outline-bg);
        border: 2px solid var(--btn-light-outline-bd);
        color: var(--btn-light-outline-txt);
    }

    /* Secondary Subtle */
    &.secondary-subtle {
        background: none;
        border: none;
        color: var(--btn-secondary-subtle-txt);
        box-shadow: none;
    }

    &.secondary-subtle:hover {
        background: none;
        border: none;
        color: var(--btn-secondary-subtle-txt-h);
    }

    &.secondary-subtle:focus,
    &.secondary-subtle:active {
        background: none;
        border: none;
        color: var(--btn-secondary-subtle-txt-f);
    }

    &.secondary-subtle:disabled {
        background: none;
        border: none;
        color: var(--btn-secondary-subtle-txt);
    }

    &:hover .icon-arrow-up {
        transform: translateY(-2px);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const IconWrapper = styled.div`
    width: 2.2rem;
    height: 2.2rem;
    display: grid;
    place-items: center;
    transform: translateY(-1px);
    transition: transform 0.3s ease;

    .icon {
        width: 2rem;
        height: 2rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        /* display: none; */
    }
`;

interface Props {
    curLang: string;
    fs: string;
    appearance: string;
    width?: string;
    icon?: any;
    txt?: string | null;
    handleClick: () => void;
    isDisabled?: boolean;
}

// primary, primary-outline
// secondary, secondary-outline
const Button: React.FC<Props> = ({ curLang, fs, appearance, width, icon, txt, handleClick, isDisabled = false }) => {
    return (
        <Wrapper
            className={`lang-${curLang} fs-${fs} ${appearance} ${width}`}
            onClick={handleClick}
            disabled={isDisabled}
        >
            {txt && txt}
            {icon && <IconWrapper>{icon}</IconWrapper>}
        </Wrapper>
    );
};

Button.defaultProps = {
    fs: '16'
};

export default Button;
