import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ProductCardLayout,
    StyledWrapperStandard,
    StyledInner,
    TitleWrapper,
    IntroWrapper,
    HighlightWrapper
} from '../../components/common.style';
import { H4Title, HighlightText, Text1, Text3 } from '../../components/common.styles.font';
import CardProduct from '../../components/CardProduct';
import { productCardList } from '../../data/mainDetails';

interface Props {
    curLang: string;
    openLightbox: (arg0: any) => void;
}

const ProductSection: React.FC<Props> = ({ curLang, openLightbox }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard id="main__products" className="bg-tertiary">
            <StyledInner>
                <TitleWrapper className="title-intro-wrapper">
                    <H4Title className={`lang-${curLang} title-span-tertiary`} data-aos-delay="80" data-aos="fade-up">
                        <span>{t('main.products.title')}</span>
                    </H4Title>
                </TitleWrapper>
                <IntroWrapper data-aos-delay="80" data-aos="fade-up">
                    <Text1 className={`lang-${curLang}`}>{t('main.products.intro')}</Text1>
                </IntroWrapper>
                <HighlightWrapper>
                    <HighlightText className={`lang-${curLang} large`}>
                        {t('main.products.highlightTitle')}
                    </HighlightText>
                    <Text3 className={`lang-${curLang}`}>{t('main.products.highlightDesc')}</Text3>
                </HighlightWrapper>
                <ProductCardLayout>
                    {productCardList.map(
                        ({
                            id,
                            product,
                            title,
                            subtitle,
                            description,
                            img,
                            imgAlignLeft,
                            tagArr,
                            hightlightArr,
                            isDiscoverMore,
                            linkUrl
                        }) => (
                            <CardProduct
                                key={id}
                                curLang={curLang}
                                img={img}
                                imgAlignLeft={imgAlignLeft}
                                tagArr={tagArr}
                                hightlightArr={hightlightArr}
                                title={t(title)}
                                subtitle={t(subtitle)}
                                description={t(description)}
                                isDiscoverMore={isDiscoverMore}
                                link={product === 'uPointDMS' ? `${linkUrl}/${curLang}` : `/${curLang}/${linkUrl}`}
                                openLightbox={openLightbox}
                            />
                        )
                    )}
                </ProductCardLayout>
            </StyledInner>
        </StyledWrapperStandard>
    );
};

export default ProductSection;
