import React from 'react';
import { useTranslation } from 'react-i18next';
import { UilCheck, UilArrowUpRight } from '@iconscout/react-unicons';
import styled from 'styled-components';
import { Text2 } from './common.styles.font';
import { tabletViews } from '../utils/media';

const Wrapper = styled.div`
    box-shadow:
        0px 4px 6px rgba(0, 0, 0, 0.12),
        0px 0px 20px rgba(0, 0, 0, 0.04);
    background: #fff;
    border-radius: var(--card-bd-r);
    text-align: left;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(28rem, 52rem) minmax(38rem, 57rem);
    gap: 3rem;

    &.img-left {
        grid-template-columns: minmax(28rem, 52rem) minmax(38rem, 57rem);
        grid-template-areas: 'img content';
    }

    &.img-right {
        grid-template-columns: minmax(38rem, 57rem) minmax(28rem, 52rem);
        grid-template-areas: 'content img';
    }

    img {
        grid-area: img;
        cursor: zoom-in;
        width: 100%;
        padding: 1.2rem 0 1.2rem 1.6rem;
        align-self: center;
    }

    /* max-width: 820px */
    @media ${tabletViews.lg} {
        display: block;

        img {
            padding: 1.2rem;
            align-self: center;
        }
    }
`;

const Inner = styled.div`
    grid-area: content;
    padding: 3rem 5rem 3rem 3rem;
    display: grid;
    grid-template-rows: max-content 1fr;

    /* max-width: 992px */
    @media ${tabletViews.xlg} {
        padding: 3rem;
    }
`;

const CardHeader = styled.div`
    margin-bottom: 2.2rem;
`;

const TagsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 1.2rem;
`;

const Tag = styled.div`
    font-family: 'Roboto', 'Assistant', sans-serif;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cfeae6;
    border-radius: var(--tag-bd-r);
    padding: 0.3rem 0.5rem 0.4rem;
    color: var(--tertiary-600);
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.75px;
`;

const CardBody = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: repeat(2, max-content);
    row-gap: 3rem;
`;

const ProductName = styled.div`
    font-family: 'Assistant', 'Roboto', sans-serif;
    font-size: 2.6rem;
    font-weight: 800;
    margin-bottom: 1.2rem;
`;

const HighLightsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.2rem;
    row-gap: 0.6rem;
`;

const Highlight = styled.div`
    display: flex;
    align-items: center;
    gap: 0.6rem;
`;

const Icon = styled.div`
    width: 2.2rem;
    height: 2.2rem;

    .icon {
        width: 2.2rem;
        height: 2.2rem;
        transform: translateY(1px);
    }

    .icon-check {
        color: var(--tertiary-500);
    }

    .icon-arrow-up {
        width: 2rem;
        height: 2rem;
    }
`;

const LearnMoreLink = styled.a`
    justify-self: start;
    align-self: flex-start;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--discover-more-bd);
    padding: 0.5rem 1.4rem 0.7rem;
    border: 1px solid var(--link-discover-more-bd);
    background: var(--link-discover-more-bg);
    color: var(--link-discover-more-txt);
    font-family: 'Roboto', 'Assistant', sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    line-height: 2.2rem;
    letter-spacing: 0.1px;
    display: flex;
    column-gap: 0.6rem;

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', 'open-sans';
    }

    &:hover {
        border: 1px solid var(--link-discover-more-bd-h);
        background: var(--link-discover-more-bg-h);
        color: var(--link-discover-more-txt-h);
    }

    &:focus,
    &:active {
        border: 1px solid var(--link-discover-more-bd-f);
        background: var(--link-discover-more-bg-f);
        color: var(--link-discover-more-txt-f);
    }

    &:hover .icon-arrow-up {
        transform: translateY(-1px);
    }
`;

interface PointList {
    id: string;
    txt: string;
}

interface Props {
    curLang: string;
    img: any;
    imgAlignLeft: boolean;
    tagArr: PointList[];
    hightlightArr: PointList[];
    title: string;
    subtitle: string;
    description: string;
    isDiscoverMore: boolean;
    link: string;
    openLightbox: (arg0: any) => void;
}

const CardProduct: React.FC<Props> = ({
    curLang,
    img,
    imgAlignLeft,
    tagArr,
    title,
    hightlightArr,
    description,
    isDiscoverMore,
    link,
    openLightbox
}) => {
    const { t } = useTranslation();
    return (
        <Wrapper data-aos-delay="160" data-aos="fade-up" className={imgAlignLeft ? 'img-left' : 'img-right'}>
            <img src={img} alt="product" onClick={() => openLightbox(img)} />
            <Inner>
                <CardHeader>
                    <TagsWrapper>
                        {tagArr.map(({ id, txt }) => (
                            <Tag key={id}>{t(txt)}</Tag>
                        ))}
                    </TagsWrapper>

                    <ProductName className={`lang-${curLang}`}>{title}</ProductName>
                    <HighLightsWrapper>
                        {hightlightArr.map(({ id, txt }) => (
                            <Highlight key={id}>
                                <Icon>
                                    <UilCheck className="icon icon-check" />
                                </Icon>
                                <Text2 className={`lang-${curLang}`}>{t(txt)}</Text2>
                            </Highlight>
                        ))}
                    </HighLightsWrapper>
                </CardHeader>

                <CardBody>
                    <Text2 className={`lang-${curLang}`}>{description}</Text2>
                    <LearnMoreLink className={`lang-${curLang}`} href={link} target="_blank">
                        {isDiscoverMore ? t('common.btn.discoverMore') : t('common.txt.comingSoon')}
                        <Icon>
                            <UilArrowUpRight className="icon icon-arrow-up" />
                        </Icon>
                    </LearnMoreLink>
                </CardBody>
            </Inner>
        </Wrapper>
    );
};

export default CardProduct;
