import React from 'react';
import { useTranslation } from 'react-i18next';
import { H4Title, Text2 } from '../../components/common.styles.font';
import styled from 'styled-components';
import { StyledWrapperStandard, StyledInner } from '../../components/common.style';

export const Inner = styled(StyledInner)`
    display: flex;
    flex-direction: column;
    gap: 8rem;
`;

export const Section = styled.div`
    max-width: 82rem;
`;

export const SectionIntro = styled.div`
    margin-bottom: 2.4rem;

    h4 {
        text-align: left;
        margin-bottom: 1.6rem;
    }
`;

export const SectionList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
`;

export const SectionListItem = styled.li`
    position: relative;
    left: -1.4rem;
    list-style-position: outside;
    list-style-type: circle;
    left: 1.4rem;

    p {
        font-weight: 700;
        color: var(--txt-active);
    }
`;

interface Point {
    id: string;
    txt: string;
}

interface ServiceItem {
    id: string;
    title: string;
    intro: string;
    pointList: Point[];
}

interface Props {
    curLang: string;
    overview: ServiceItem[];
}

const ServicesContentSection: React.FC<Props> = ({ curLang, overview }) => {
    const { t } = useTranslation();

    return (
        <StyledWrapperStandard id="services__overview" className="bg-tertiary">
            <Inner>
                {overview.map(({ id, title, intro, pointList }) => (
                    <Section key={id}>
                        <SectionIntro>
                            <H4Title className={`lang-${curLang}`} data-aos-delay="80" data-aos="fade-up">
                                {t(title)}
                            </H4Title>
                            <Text2 className={`lang-${curLang}`}>{t(intro)}</Text2>
                        </SectionIntro>
                        <SectionList>
                            {pointList.map(({ id, txt }) => (
                                <SectionListItem key={id}>
                                    <Text2 className={`lang-${curLang}`}>{t(txt)}</Text2>
                                </SectionListItem>
                            ))}
                        </SectionList>
                    </Section>
                ))}
            </Inner>
        </StyledWrapperStandard>
    );
};

export default ServicesContentSection;
