import styled from 'styled-components';
import { desktopViews, tabletViews, mobileViews } from '../../utils/media';
import { hamburgerMenuZIndex, headerHeight, headerIndex, menuWrapperIndex } from '../../utils/common';

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    z-index: ${headerIndex};
    height: ${headerHeight}rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem 0 3rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.show {
        top: 0;
        background: rgba(255, 255, 255, 0.8);
    }

    &.hide {
        top: -6rem;
    }

    &.landing {
        background-color: var(--layout-header-bg);
        border-bottom: 1px solid var(--layout-header-bd);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    }

    &.hamburger-active {
        background-color: var(--layout-header-bg);
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        padding: 0 2.4rem 0 2rem;
    }

    /* max-width: 480px */
    @media ${mobileViews.xlg} {
        padding: 0 1.6rem;
    }
`;

export const Logo = styled.a`
    display: flex;
    align-items: center;

    img {
        width: 2.6rem;
        margin-right: 1rem;
    }

    &.active {
        z-index: ${hamburgerMenuZIndex + 1};
    }
`;

export const Nav = styled.nav`
    display: flex;
    align-items: center;
    gap: 3rem;

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        gap: 2rem;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        gap: 3rem;

        &.active .header__nav-hamburger,
        &.active .header__nav-lang {
            z-index: ${hamburgerMenuZIndex + 1};
        }
    }
`;

export const ListItem = styled.li`
    position: relative;
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.75rem;

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', sans-serif;
    }

    /* max-width: 1440px */
    @media ${desktopViews.lg} {
        font-size: 1.7rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        font-size: 1.6rem;
    }

    &.header__nav-products,
    &.header__nav-services,
    &.header__nav-contact {
        display: block;
    }

    &.header__nav-hamburger {
        display: none;
    }

    /* max-width: 600px */
    @media ${tabletViews.xs} {
        &.header__nav-products,
        &.header__nav-services,
        &.header__nav-contact {
            display: none;
        }

        &.header__nav-hamburger {
            display: block;
        }
    }
`;

export const ListMenuBtn = styled.button`
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    color: var(--txt-active);
    transform: translateY(-1px);
    font-size: 1.75rem;

    &:hover {
        color: var(--secondary-600);
    }

    /* &:focus, */
    &:active {
        color: var(--secondary-500);
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', sans-serif;
    }

    /* max-width: 1440px */
    @media ${desktopViews.lg} {
        font-size: 1.7rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        width: auto;
        font-size: 1.6rem;
        margin-right: 0;
        gap: 0.2em;

        .icon {
            width: 2rem;
        }
    }
`;

export const ListMenuLink = styled.a`
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-items: center;
    color: var(--txt-active);
    transform: translateY(-1px);
    font-size: 1.75rem;

    &:hover {
        color: var(--secondary-600);
    }

    /* &:focus, */
    &:active {
        color: var(--secondary-500);
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', sans-serif;
    }

    /* max-width: 1440px */
    @media ${desktopViews.lg} {
        font-size: 1.7rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        width: auto;
        font-size: 1.6rem;
        margin-right: 0;
    }
`;

export const ListMenu = styled.ul`
    width: fit-content;
    background: #fff;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
    border-radius: var(--menu-bd-r);
    position: absolute;
    top: 3.8rem;
    right: 1rem;
    transform: translateX(50%);
    padding: 0.6rem 0;
    z-index: ${menuWrapperIndex};
    min-width: 12rem;

    li {
        padding: 0.6rem 3.6rem 0.6rem 1.4rem;
        word-break: keep-all;
        white-space: nowrap;
    }

    li:hover {
        background: var(--secondary-50);
    }

    li:hover button {
        color: var(--txt-active);
    }

    li:hover button.active {
        color: var(--secondary-700);
    }

    li:hover button:disabled {
        color: var(--txt-disabled);
    }
`;

export const LinkBtn = styled.button`
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    color: var(--txt-inactive);
    display: flex;
    align-items: center;
    gap: 1.2rem;
    transition: none;
    text-transform: uppercase;
    font-size: 1.65rem;

    &.active {
        color: var(--secondary-600);
    }

    &:disabled {
        color: var(--txt-disabled);
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', sans-serif;
    }

    /* max-width: 1440px */
    @media ${desktopViews.lg} {
        font-size: 1.6rem;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        font-size: 1.5rem;
    }
`;

export const IconBtn = styled.a`
    display: block;
    transform: translateY(2px);
    color: var(--txt-active);
    margin-left: 1.2rem;

    .icon {
        width: 2.2rem;
    }

    &:hover {
        color: var(--secondary-600);
    }

    /* &:focus, */
    &:active {
        color: var(--secondary-500);
    }

    .tooltip {
        left: initial;
        right: -1rem;
        transform: initial;
    }

    &:hover .tooltip {
        opacity: 1;
        visibility: visible;
    }
`;

export const Tag = styled.span`
    font-family: 'Roboto', 'Assistant', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    background: var(--warning-50);
    color: var(--warning-600);
    border: 1px solid var(--warning-500);
    padding: 0rem 0.4rem 0.1rem;
    border-radius: var(--tag-bd-r);
`;

/*======= Landing page =======*/
export const Title = styled.h1`
    font-family: 'Ubuntu', 'Assistant', sans-serif;
    font-size: 1.8rem;
    font-weight: 600;
    color: #000;

    &.short {
        display: none;
    }

    /* max-width: 768px */
    @media ${tabletViews.sm} {
        font-size: 1.6rem;
    }

    /* max-width: 540px */
    @media ${tabletViews.xxs} {
        font-size: 1.5rem;

        &.long {
            display: none;
        }

        &.short {
            display: block;
        }
    }
`;

/*======= Hamburger menu =======*/
export const HamburgerBtn = styled.button``;

export const HamburgerMenu = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: ${hamburgerMenuZIndex};
    width: 100%;
    height: 100vh;
    padding-top: ${headerHeight}rem;
    background-color: var(--layout-header-bg);
    transition:
        transform 0.15s ease-in,
        opacity 0.15s ease-out;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
`;

export const HamburgerMenuListItem = styled.li`
    &:hover {
        color: var(--secondary-600);
    }

    &:active {
        color: var(--secondary-500);
    }

    &.main {
        border-bottom: 1px solid var(--neutral-300);
    }

    &.main:first-of-type {
        border-top: 1px solid var(--neutral-300);
    }

    &.sub {
        border-bottom: 1px solid var(--neutral-300);
    }

    &.sub:first-of-type {
        border-top: 1px solid var(--neutral-300);
    }

    &.sub:last-of-type {
        border-bottom: none;
    }
`;

export const HamburgerMenuListBtn = styled.button`
    display: block;
    font-family: 'Roboto Mono', 'Ubuntu', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--txt-active);
    transform: translateY(-1px);
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    &.main {
        padding: 1.4rem 3rem;
    }

    &.sub {
        padding: 1.4rem 6rem;
    }

    &.lang-zh {
        font-family: 'Noto Sans TC', 'Roboto', sans-serif;
    }
`;

export const MenuBtnsWrapper = styled.div`
    padding: 3.4rem 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.4rem;

    button {
        text-transform: uppercase;
    }
`;

export const HamburgerMenuFooter = styled.div`
    position: absolute;
    bottom: 0;
`;
